
import { defineComponent } from "vue";
import SalesOppsDataTableWrapper from "@/components/Sales/SalesOppsDataWrapper.vue";
import SalesOppDialog from "./SalesOppDialog.vue";
import SaleOpp from "@/types/saleOpps";
import Card from "primevue/card";
import SalesHeaderButtons from "@/components/Sales/SalesHeaderButtons.vue";
import { mapState, mapActions, mapGetters } from "vuex";
export default defineComponent({
  name: "Sales",
  components: {
    SalesOppsDataTableWrapper,
    SalesOppDialog,
    Card,
    SalesHeaderButtons,
  },
  computed: {
    ...mapGetters({
      getFirstRow: "saleOpps/getFirstRow",
      getNewOppsCount: "salesInquiry/getNewOppsCount",
    }),
  },
  mounted() {
    this.first = this.getFirstRow;
  },
  data() {
    return {
      items: [] as Array<SaleOpp>,
      saleOpp: null as unknown,
      selectedRow: null,
      first: 0,
      rows: 10,
      page: 1,
      order: false,
      orderRecordsBy: "",
      sortBy: "",
      sortOrder: 1,
      sortField: "",
      showDialog: false as boolean,
      isEditing: false as boolean,
      isResultView: true as boolean,
      salesOpps: [] as Array<SaleOpp>,
      isLoadingSalesOpps: true as boolean,
      lastUpdated: Date.now(),
      isProduction: this.isProduction,
    };
  },
  created() {
    this.isLoadingSalesOpps = true;
    this.fetchSaleOpps();
  },
  inject: ["isProduction"],
  methods: {
    ...mapActions({
      fetchSaleOppsRecords: "saleOpps/fetchSaleOpps",
      addOpenedSalesOrder: "salesInquiry/addOpenedSalesInquiry",
    }),
    printExportData() {
      (this.$refs as any).salesOppsTable.printTable();
    },
    clearFilters() {
      (this.$refs as any).salesOppsTable.clearAllFiltersAndFetch();
    },
    refreshData() {
      if (this.showDialog) {
        this.showDialog = false;
      }
      (this.$refs.salesOppsTable as any).fetchSaleOpps(false);
    },
    openSalesDialog(read: boolean) {
      if(this.isProduction) {
        this.showDialog = true;
        if (!read) {
          this.saleOpp = null;
        }
        this.isEditing = read;
      } else {
      const count = this.getNewOppsCount === undefined ? 1 : this.getNewOppsCount + 1;
        const itemToEdit = {
          id: `New Opp-${count}`,
          saleType: "opportunities",
          oldRecord: {},
        };

        this.addOpenedSalesOrder(itemToEdit);
        this.$router.push(`/sales/opportunities/${itemToEdit.id}`)
      }
    },
    hideDialog(hidden: boolean) {
      this.showDialog = hidden;
      this.saleOpp = null;
      this.isEditing = false;
    },
    isResultViewChange(event: boolean) {
      this.isResultView = event;
    },
    fetchSaleOpps() {
      this.fetchSaleOppsRecords({
        correls: "cust_name",
      })
        .then((resp) => {
          this.salesOpps = resp.data;
        })
        .finally(() => {
          this.isLoadingSalesOpps = false;
        });
    },
    isDataUpdated(event: number) {
      this.lastUpdated = event;
    },
  },
});
